import { template as template_2262281e1cd84fc4b9e90c4be1885db8 } from "@ember/template-compiler";
const FKLabel = template_2262281e1cd84fc4b9e90c4be1885db8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
