import { template as template_25704f323f5543a5a8bdb4719a947fa3 } from "@ember/template-compiler";
const WelcomeHeader = template_25704f323f5543a5a8bdb4719a947fa3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
