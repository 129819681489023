import { template as template_f2decc854ae64c7dad41ce43c605d1ed } from "@ember/template-compiler";
const FKText = template_f2decc854ae64c7dad41ce43c605d1ed(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
