import { template as template_6201f1b9c5b0472880337a9a901f4a67 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_6201f1b9c5b0472880337a9a901f4a67(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
